<template>
  <div id="invoices-page">
    <app-box width="large">
      <template #legend>Invoices</template>
      <template #title>{{ cInvoices.length ? (minimal ? 'Invoicing' : 'Invoice your clients') : 'Create your first invoice' }}</template>
      <div class="mb-4 md:mb-6" :class="[cInvoices.length ? ['md:flex md:justify-end'] : []]">
        <p v-if="!cInvoices.length || !minimal" class="text-sm md:text-base text-gray-700 leading-snug">
          <template v-if="cInvoices.length">
            Here are the invoices you have created so far.
            Don't forget to mark invoices as paid when you receive payments for them.
          </template>
          <template v-else-if="!minimal">
            Go ahead and create your first invoice by clicking on the button below.
            Once created you will have the option to download a PDF version of it.
          </template>
        </p>
        <div :class="[cInvoices.length ? [minimal ? ['-mt-28 md:-mt-20 text-center'] : ['mt-4 md:mt-0 md:pl-8 md:self-end']] : [minimal ? ['text-center -mt-28 md:-mt-20'] : ['text-center mt-8']]]">
          <app-button :class="[!minimal ? ['relative md:pl-10'] : []]" type="add" @click="openModal()">
            <svg v-if="!minimal" xmlns="http://www.w3.org/2000/svg" class="hidden md:inline absolute left-4 top-3.5 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span :class="{ 'md:ml-1.5': !minimal}">New invoice</span>
          </app-button>
        </div>
      </div>
      <app-table v-if="cInvoices.length" class="-mb-8 md:mb-0 -mx-5 md:mx-0" :class="minimal ? ['-mt-1 md:-mt-14'] : []">
        <app-thead>
          <app-tr>
            <app-th>
              Title / <span class="inline md:hidden">total</span><span class="hidden md:inline">description</span>
            </app-th>
            <app-th class="hidden md:table-cell">
              Total / Tax
            </app-th>
            <app-th class="hidden md:table-cell">
              Status
            </app-th>
            <app-th>
              Details
            </app-th>
            <app-th />
          </app-tr>
        </app-thead>
        <app-tbody>
          <transition-group move-class="transition-transform duration-500" enter-active-class="transition-colors duration-slow" enter-from-class="bg-yellow-200" enter-to-class="bg-transparent" leave-active-class="transition-opacity duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <tr v-for="invoice in cInvoices" :id="invoice.lastAdded ? 'last-added' : null" :key="invoice.uuid">
              <app-td>
                <app-td-content class="md:max-w-64">
                  <template #first>{{ invoice.title }}</template>
                  <template #second>
                    <span class="inline md:hidden">{{ formatMoney(invoice.total, invoice.currency) }}</span>
                    <span class="hidden md:inline">{{ invoice.description || '-' }}</span>
                  </template>
                </app-td-content>
              </app-td>
              <app-td class="hidden md:table-cell">
                <app-td-content>
                  <template #first>{{ formatMoney(invoice.total, invoice.currency) }}</template>
                  <template #second>{{ invoice.taxRate ? `${formatMoney(invoice.tax, invoice.currency)} (${invoice.taxRate}%)` : 'No tax applied' }}</template>
                </app-td-content>
              </app-td>
              <app-td class="hidden md:table-cell">
                <span :class="[invoice.status === 'Paid' ? ['border-green-400 from-green-300 to-gray-200 lg:to-transparent text-green-700'] : [], invoice.status === 'Pending' ? ['border-gray-400 from-gray-300 to-gray-200 lg:to-transparent text-gray-700'] : [], invoice.status === 'Overdue' ? ['border-red-400 from-red-300 to-gray-200 lg:to-transparent text-red-700'] : []]" class="w-20 inline-flex items-center pl-1.5 py-0.5 text-xs font-medium bg-gradient-to-r border-l-4">
                  {{ invoice.status }}
                </span>
              </app-td>
              <app-td>
                <app-td-content>
                  <template #subtle>
                    Number: {{ invoice.reference }}<br>
                    Created: {{ formatDate(invoice.createdOn) }}<br>
                    {{ invoice.status === 'Paid' ? `Paid: ${formatDate(invoice.paidOn)}` : invoice.dueOn ? `Due: ${formatDate(invoice.dueOn)}` : '' }}
                  </template>
                </app-td-content>
              </app-td>
              <app-td class="w-1">
                <app-dropdown :options="invoice.options" @click="handleChoice(invoice.uuid, $event)" />
              </app-td>
            </tr>
          </transition-group>
        </app-tbody>
      </app-table>
      <transition enter-active-class="transition-opacity duration-500" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition-opacity duration-500" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <app-modal v-if="showModal" @close="closeModal">
          <template #title>
            {{ editUuid ? "Edit invoice" : "Create new invoice" }}
          </template>
          <template v-if="activeForm == 'amount'">
            <app-form @submit.prevent="submitAmountForm">
              <app-form-field>
                <app-label id="project-uuid">
                  Projects
                </app-label>
                <app-multiple-select id="project-uuid" v-model="form.fields.projectUuids" :options="cProjects" />
              </app-form-field>
              <div v-if="cProjectType == 'Hourly' || (cProjectType == 'Monthly' && form.fields.title == '')" class="flex">
                <app-form-field :class="{'w-1/2 pr-1': cProjectType == 'Hourly'}">
                  <app-label id="from-date">
                    {{ cProjectType == 'Hourly' ? 'From date' : 'Month (for use in invoice title)' }}
                  </app-label>
                  <app-input id="from-date" v-model="form.fields.fromDate" :class="{'empty-date-input': !form.fields.fromDate}" :type="cProjectType == 'Hourly' ? 'date' : 'month'" />
                </app-form-field>
                <app-form-field v-if="cProjectType == 'Hourly'" class="w-1/2 pl-1">
                  <app-label id="to-date">
                    To date
                  </app-label>
                  <app-input id="to-date" v-model="form.fields.toDate" :class="{'empty-date-input': !form.fields.toDate}" type="date" />
                </app-form-field>
              </div>
              <app-button class="mt-3 transition-colors" :disabled="loading || invalidAmountForm">
                Calculate
              </app-button>
            </app-form>
            <hr class="mt-8 mb-2 border-gray-300">
            <p class="w-1/2 text-xs">
              <a href="#" class="text-xs text-gray-500 underline hover:no-underline" @click.prevent="activeForm = 'invoice'">
                &larr; Go back
              </a>
            </p>
          </template>
          <template v-else>
            <app-form @submit.prevent="submitInvoiceForm">
              <div class="flex">
                <app-form-field class="w-1/3 mr-2">
                  <app-label id="reference">
                    Number
                  </app-label>
                  <span v-if="canGenerateReference" class="hidden md:inline text-xs text-gray-500 lowercase font-light">(<a v-tooltip="tooltipTextGenerate" href="#" class="underline hover:no-underline" @click.prevent="generateReference">Generate</a>)</span>
                  <app-input id="reference" v-model.trim="form.fields.reference" maxlength="45" />
                </app-form-field>
                <app-form-field class="w-2/3">
                  <app-label id="title">
                    Title
                  </app-label>
                  <app-input id="title" v-model.trim="form.fields.title" maxlength="45" />
                </app-form-field>
              </div>
              <app-form-field>
                <app-label id="description">
                  Description
                </app-label>
                <app-text-area id="description" v-model.trim="form.fields.description" rows="2" maxlength="125" />
              </app-form-field>
              <div class="flex">
                <app-form-field class="w-4/12 mr-2">
                  <app-label id="amount">
                    Amount
                  </app-label>
                  <span class="hidden md:inline text-xs text-gray-500 lowercase font-light">(<a v-tooltip="tooltipTextCalculate" href="#" class="underline hover:no-underline" @click.prevent="activeForm = 'amount'">Calculate</a>)</span>
                  <app-input id="amount" v-model="form.fields.amount" type="number" min="0" max="999999" />
                </app-form-field>
                <div class="flex w-8/12">
                  <app-form-field class="w-1/3 mr-2">
                    <app-label id="tax-rate">
                      Tax rate
                    </app-label>
                    <app-input id="tax-rate" v-model="form.fields.taxRate" type="number" min="0" max="99" />
                  </app-form-field>
                  <app-form-field class="w-2/3">
                    <app-label id="total">
                      Total
                    </app-label>
                    <app-input id="total" v-model="total" :disabled="true" />
                  </app-form-field>
                </div>
              </div>
              <div class="flex">
                <app-form-field class="w-4/12 mr-2">
                  <app-label id="currency">
                    Currency
                  </app-label>
                  <app-select id="currency" v-model="form.fields.currency" :options="currencies" />
                </app-form-field>
                <app-form-field class="w-8/12">
                  <app-label id="due-on">
                    Due date
                  </app-label>
                  <app-input id="due-on" v-model="form.fields.dueOn" :class="{'empty-date-input': !form.fields.dueOn}" type="date" />
                </app-form-field>
              </div>
              <div v-if="editUuid" class="flex">
                <app-form-field class="w-1/2 mr-2">
                  <app-label id="created-on">
                    Created date
                  </app-label>
                  <app-input id="created-on" v-model="form.fields.createdOn" :class="{'empty-date-input': !form.fields.createdOn}" type="date" />
                </app-form-field>
                <app-form-field class="w-1/2">
                  <app-label id="paid-on">
                    Paid date
                  </app-label>
                  <app-input id="paid-on" v-model="form.fields.paidOn" :class="{'empty-date-input': !form.fields.paidOn}" type="date" />
                </app-form-field>
              </div>
              <div class="flex">
                <app-form-field class="w-1/2 mr-1">
                  <app-label id="from-address">
                    Your address
                  </app-label>
                  <span v-if="canPopulateFromAddress" class="hidden md:inline text-xs text-gray-500 lowercase font-light">(<a v-tooltip="tooltipTextPopulate" href="#" class="underline hover:no-underline" @click.prevent="populateFromAddress">Populate</a>)</span>
                  <app-text-area id="from-address" v-model.trim="form.fields.fromAddress" rows="4" maxlength="250" />
                </app-form-field>
                <app-form-field class="w-1/2 ml-1">
                  <app-label id="to-address">
                    Client address
                  </app-label>
                  <app-text-area id="to-address" v-model.trim="form.fields.toAddress" rows="4" maxlength="250" />
                </app-form-field>
              </div>
              <app-form-field>
                <app-label id="footer">
                  Footer
                </app-label>
                <app-text-area id="footer" v-model.trim="form.fields.footer" rows="2" maxlength="250" />
              </app-form-field>
              <app-button class="mt-3 transition-colors" :disabled="loading || invalidForm">
                {{ editUuid ? "Update" : "Create" }}
              </app-button>
            </app-form>
          </template>
        </app-modal>
      </transition>
    </app-box>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  name: "Invoices",
  data() {
    return {
      activeForm: "invoice",
      editUuid: "",
      form: {
        fields: {
          fromDate: "",
          toDate: "",
          projectUuids: [],
          fromAddress: "",
          toAddress: "",
          amount: 0,
          createdOn: "",
          currency: "",
          description: "",
          dueOn: "",
          footer: "",
          paidOn: "",
          reference: "",
          taxRate: 0,
          title: "",
        },
      },
      account: {},
      invoices: [],
      projects: [],
      showModal: false,
      tooltipTextGenerate: `Generate a new number by taking the number from your most recently created invoice and incrementing it by one.`,
      tooltipTextCalculate: `Calculate amount based on the hours logged within a date range and / or the configured billing type for selected project(s).`,
      tooltipTextPopulate: `Populate using the name and address from your account.`,
    };
  },
  computed: {
    ...mapGetters(["loggedIn"]),
    ...mapState(["loading", "minimal"]),
    cProjectType() {
      if (!this.form.fields.projectUuids.length) return "";
      const uuid = this.form.fields.projectUuids[0];
      return this.projects.find((p) => p.uuid === uuid).type;
    },
    canGenerateReference() {
      return this.cInvoices.length && /^\d+$/.test(this.cInvoices[0].reference);
    },
    canPopulateFromAddress() {
      return this.account.address && !this.form.fields.fromAddress;
    },
    cInvoices() {
      return [...this.invoices]
        .sort((a, b) => {
          const aDate = new Date(a.createdOn).getTime();
          const bDate = new Date(b.createdOn).getTime();
          if (aDate !== bDate) return bDate - aDate;
          return b.reference - a.reference;
        })
        .map((i) => {
          i.tax = i.taxRate ? i.amount * (i.taxRate / 100) : 0;
          i.total = i.taxRate ? i.amount + i.tax : i.amount;
          if (i.paidOn) {
            i.status = "Paid";
          } else {
            i.status = "Pending";
            if (i.dueOn) {
              const today = U.localDate().replace(/-/g, "");
              const due = i.dueOn.replace(/-/g, "");
              if (due < today) {
                i.status = "Overdue";
              }
            }
          }
          i.options = ["Edit"];
          i.options.push("Download PDF");
          i.options.push(i.paidOn ? "Mark as unpaid" : "Mark as paid");
          i.options.push("Clone");
          i.options.push("Delete");
          return i;
        });
    },
    cProjects() {
      return [...this.projects]
        .filter((p) => p.active && p.type !== "None")
        .sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        })
        .map((p) => ({
          name: `${p.name} (${p.type.toLowerCase()})`,
          value: p.uuid,
          disabled: this.cProjectType !== "" && this.cProjectType !== p.type,
        }));
    },
    invalidForm() {
      const { fromAddress, toAddress, amount, currency, reference, title } =
        this.form.fields;
      return (
        fromAddress === "" ||
        toAddress === "" ||
        amount === "" ||
        currency === "" ||
        reference === "" ||
        title === ""
      );
    },
    invalidAmountForm() {
      const { projectUuids, fromDate, toDate } = this.form.fields;
      return (
        !projectUuids.length ||
        (this.cProjectType === "Hourly" && fromDate === "") ||
        (this.cProjectType === "Hourly" && toDate === "")
      );
    },
    total() {
      let { amount, taxRate } = this.form.fields;
      amount = parseInt(amount) || 0;
      taxRate = parseInt(taxRate) || 0;
      const tax = taxRate ? amount * (taxRate / 100) : 0;
      const total = taxRate ? amount + tax : amount;
      return this.formatNumber(total);
    },
  },
  watch: {
    cProjectType(val) {
      if (val) {
        this.form.fields.fromDate = "";
        this.form.fields.toDate = "";
      }
    },
  },
  async created() {
    if (U.redirect(this.loggedIn)) return this.$router.push("/");
    await this.getData();
  },
  methods: {
    formatDate: U.formatDate,
    formatMoney: U.formatMoney,
    formatNumber: U.formatNumber,
    async getData() {
      const [{ accounts }, { projects }, { invoices }] = await U.apiAll([
        { url: "api/accounts" },
        { url: "api/projects" },
        { url: "api/invoices" },
      ]);
      this.account = accounts[0];
      this.projects = projects;
      this.invoices = invoices;
    },
    async handleChoice(uuid, option) {
      if (option === "Edit") {
        this.openModal({ type: "edit", uuid });
      } else if (option === "Mark as paid" || option === "Mark as unpaid") {
        const paidOn = option === "Mark as paid" ? U.localDate() : "";
        this.invoices = U.patch("invoice", this.invoices, uuid, { paidOn });
      } else if (option === "Download PDF") {
        window.location.href = `${U.baseUrl()}/api/invoices/${uuid}/pdf`;
      } else if (option === "Clone") {
        this.openModal({ type: "clone", uuid });
      } else if (option === "Delete") {
        this.invoices = U.delete("invoice", this.invoices, uuid);
      }
    },
    openModal({ type = "new", uuid = "" } = {}) {
      this.activeForm = "invoice";
      this.editUuid = type === "edit" ? uuid : "";
      this.currencies = U.currencies();
      let invoice;
      if (type !== "new") {
        invoice = { ...this.cInvoices.find((i) => i.uuid === uuid) };
      }
      for (const field in this.form.fields) {
        if (type === "new") {
          let defaultValue = "";
          if (field === "currency") {
            defaultValue = this.account.currency;
          } else if (field === "projectUuids") {
            defaultValue = [];
          }
          this.form.fields[field] = defaultValue;
        } else if (invoice[field] !== undefined) {
          this.form.fields[field] = invoice[field];
          if (type === "clone") {
            this.form.fields.reference = "";
            this.form.fields.dueOn = "";
            this.form.fields.createdOn = "";
            this.form.fields.paidOn = "";
          }
        }
      }
      this.showModal = true;
    },
    async submitAmountForm() {
      let { projectUuids, fromDate, toDate } = this.form.fields;
      if (this.cProjectType === "Hourly") {
        let url = `api/entries/amount?fromDate=${fromDate}&toDate=${toDate}`;
        projectUuids.forEach((projectUuid) => {
          url += `&projectUuids=${projectUuid}`;
        });
        const result = await U.api({ url });
        this.form.fields.amount = result.total;
      } else {
        this.form.fields.amount = this.form.fields.projectUuids.reduce(
          (acc, cur) => acc + this.projects.find((p) => p.uuid === cur).amount,
          0
        );
      }
      if (this.cProjectType === "Monthly" && fromDate) {
        const arr = fromDate.split("-");
        const month = U.monthNames()[parseInt(arr[1]) - 1];
        const year = arr[0];
        this.form.fields.title = `${month} ${year}`;
      }
      this.activeForm = "invoice";
      this.$store.commit("addTooltip", U.hash(this.tooltipTextCalculate));
    },
    submitInvoiceForm() {
      if (this.invalidForm) return;
      let {
        fromAddress,
        toAddress,
        amount,
        createdOn,
        currency,
        description,
        dueOn,
        footer,
        paidOn,
        reference,
        taxRate,
        title,
      } = this.form.fields;
      amount = parseInt(amount);
      taxRate = parseInt(taxRate) || 0;
      const obj = {
        fromAddress,
        toAddress,
        amount,
        createdOn,
        currency,
        description,
        dueOn,
        footer,
        paidOn,
        reference,
        taxRate,
        title,
      };
      if (this.editUuid) {
        const uuid = this.editUuid;
        this.invoices = U.patch("invoice", this.invoices, uuid, obj);
      } else {
        obj.createdOn = U.localDate();
        this.invoices = U.post("invoice", this.invoices, obj);
        U.scrollToLastAdded();
      }
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
    },
    generateReference() {
      this.form.fields.reference = parseInt(this.cInvoices[0].reference) + 1;
      this.$store.commit("addTooltip", U.hash(this.tooltipTextGenerate));
    },
    populateFromAddress() {
      this.form.fields.fromAddress = `${this.account.name}\r\n${this.account.address}`;
      this.$store.commit("addTooltip", U.hash(this.tooltipTextPopulate));
    },
  },
};
</script>
